.form-block {
  padding: 90px 0 70px;
  @media (max-width: 540px) {
    padding: 30px 0 50px;
  }
  .form-wrap {
    border: 1px solid #DEE4E9;
    box-shadow: 0px 5px 10px rgba(125, 148, 165, 0.1);
    border-radius: 3px;
    h1 {
      text-align: center;
      margin: 60px;
      @media (max-width: 420px) {
        margin: 40px;
      }
    }
  }
  form {
    max-width: 600px;
    margin: auto;

    .error {
      color: #B00020;
      font-size: 14px;
      padding: 5px 0 0 5px;
      line-height: 1.2;
    }
    button,
    button:active,
    button:focus {
      outline: none;
    }
    .btn-wrap {
      display: block;
      margin: auto;
      width: 100%;
      .main-btn {
        margin: auto;
      }
    }
  }

  form input.error,
  form select.error {
    &+div .mdc-notched-outline__trailing {
      border-color: #B00020 !important;
    }

    &+div .mdc-notched-outline__leading {
      border-color: #B00020 !important;
    }

    &+div .mdc-notched-outline__notch {
      border-color: #B00020 !important;
    }

    &+div .mdc-floating-label--float-above {
      color: #B00020 !important;
    }
  }

  form input.valid,
  form select.valid {
    &+div .mdc-notched-outline__trailing {
      border-color: #1CD688 !important;
    }

    &+div .mdc-notched-outline__leading {
      border-color: #1CD688 !important;
    }

    &+div .mdc-notched-outline__notch {
      border-color: #1CD688 !important;
    }

    &+div .mdc-floating-label--float-above {
      color: #1CD688 !important;
    }
  }

  .error_form {
    position: relative;
    label {
      position: absolute;
      top: -60px;
    }
  }
  .checkbox {
    margin-bottom: 20px;
    label {
      color: #2e4453;
      font-size: 14px;
    }
  }
  .mdc-checkbox{
    margin-bottom: 23px;
  }
  .mdc-text-field, .mdc-select {
    min-width: 280px;
    margin-bottom: 60px;
    display: flex;
    flex-direction: column;
    @media (max-width: 360px) {
      min-width: 247px;
    }
    input, select {
      min-height: 56px;
    }
  }
  button[disabled]:not([type=text]) {
    background: #D2DEE7 !important;
    border: none !important;
    cursor: default !important;
    color: #ABC4D6 !important;
    pointer-events: none !important;
    box-shadow: none!important;
  }

  .reg-text{
    font-size: 13px;
    margin: 0 15px;
    color: #A8BECE;
    text-align: center;
    padding-bottom: 40px;
    @media (max-width: 420px) {
      font-size: 11px;
    }
  }

  .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate)~.mdc-checkbox__background{
    border-color: #a8bece!important;
  }

  .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background, .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background {
    background-color: #1cd688!important;
    border-color: #1cd688!important;
  }
}
.btn-wrap {
  display: block;
  margin: auto;
  width: 100%;
  .main-btn {
    margin: auto;
  }
}