.screen-1-wrap {
  padding-top: 120px;
  @media (min-width: 1200px) {
    min-height: 700px;
  }
  @media (max-width: 767px) {
    padding-top: 20px;
  }

  .bonus50-title {
    line-height: 137px;
    font-size: 130px;
    font-weight: bold;
    color: #2E4453;
    text-shadow: 0px 4px 10px rgba(99, 117, 129, 0.25);
    @media (max-width: 1199px) {
      font-size: 111px;
    }
    @media (max-width: 767px) {
      font-size: 93px;
      text-align: center;
    }
    @media (max-width: 540px) {
      font-size: 75px;
      line-height: 1.3;
    }
    @media (max-width: 440px) {
      font-size: 55px;
    }
  }

  .bonus50-title-ph {
    line-height: 1.2;
    font-size: 80px;
    font-weight: bold;
    color: #2E4453;
    text-shadow: 0px 4px 10px rgba(99, 117, 129, 0.25);
    max-width: 730px;

    @media (max-width: 767px) {
      font-size: 48px;
      text-align: center;
      padding-bottom: 10px;
    }
  }

  h3 {
    color: #2E4453!important;
    max-width: 350px;
    font-weight: 400!important;
    padding-bottom: 30px;
    @media (max-width: 767px) {
      margin: auto;
      text-align: center;
      font-size: 22px;
      padding-top: 0!important;
    }
    @media (max-width: 336px) {
      font-size: 18px;
    }
  }
  .alert-text {
    font-size: 12px;
    color: #A8BECE;
    margin-top: 40px;
    @media (max-width: 1199px) {
      margin-top: 30px;
    }
    @media (max-width: 767px) {
      text-align: center;
      margin-top: 20px;
    }
  }
  @media (max-width: 991px) {
    .about-desktop{
      display: none;
    }
  }
  .money-mob {
    background: url("../../img/money_mob.png");
    width: 272px;
    height: 144px;
    display: none;
    @media (max-width: 1199px) {
      display: block;
    }
    @media (max-width: 767px) {
      margin: 30px auto 0;
    }
  }
  .money-wrap .money {
    background: url("../../img/money.png");
    width: 251px;
    height: 577px;
    position: absolute;
    &:nth-child(1){
      left: 180px;
      top: 100px;
    }
    &:nth-child(2){
      left: 155px;
      top: 75px;
    }
    &:nth-child(3){
      left: 130px;
      top: 50px;
    }
    &:nth-child(4){
      left: 105px;
      top: 25px;
    }
    &:nth-child(5){
      left: 80px;
    }
    @media (max-width: 1199px) {
      display: none;
    }
  }
  @media (max-width: 1199px) {
    background: linear-gradient(90deg, #E9EFF3 34%, #f5f9fc 10%);
  }
  @media (max-width: 991px) {
    background: linear-gradient(79.52deg, #F1F5F8 11.63%, #F5F9FC 100%);
  }
  @media (max-width: 767px) {
    .main-btn {
      margin: auto;
    }
  }
}
.about-list {
  margin-top: 35px;
  .check-icon {
    padding-left: 35px;
    max-width: 253px;
    margin-bottom: 40px;
    @media (min-width: 1200px) {
      margin-bottom: 45px;
    }
  }
  p {
    position: relative;
    &:before {
      background: url(../../img/Green-check.png) no-repeat;
      content: '';
      position: absolute;
      width: 24px;
      height: 25px;
      left: 0;
    }
  }
}
.about-mob {
  display: none;
  @media (max-width: 991px) {
    display: block;
  }
}
