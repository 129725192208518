//===============================
//
// BASE FONTS VARIABLES
//
//===============================

//=============== SETTING
//@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,500,700');
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700');

$site-font-roboto: "Roboto", sans-serif;

html,
body {
  font-family: $site-font-roboto;
  overflow-x: hidden;
}

//=============== SETTING FOR H1

$font-family-h1: inherit;
$font-weight-h1: 700;
$font-size-h1: 48px;
$line-height-h1: 1.1;
$text-transform-h1: none;
$color-h1: #2E4453;
$margin-top-h1: 20px;
$margin-bottom-h1: 10px;

h1 {
  font-family:              $font-family-h1;
  font-weight:              $font-weight-h1;
  font-size:                $font-size-h1;
  line-height:              $line-height-h1;
  text-transform:           $text-transform-h1;
  color:                    $color-h1;
  margin-top:               $margin-top-h1;
  margin-bottom:            $margin-bottom-h1;

  @media (max-width: 1199px) {
    font-size: 42px;
  }
  @media (max-width: 767px) {
    font-size: 34px;
    margin-bottom: 20px;
  }
  @media (max-width: 540px) {
    font-size: 30px;
  }
  @media (max-width: 420px) {
    font-size: 24px;
  }
}

//=============== SETTING FOR H2

$font-family-h2: inherit;
$font-weight-h2: 500;
$font-size-h2: 30px;
$line-height-h2: 1.1;
$text-transform-h2: none;
$color-h2: #A8BECE;
$margin-top-h2: 20px;
$margin-bottom-h2: 10px;

h2 {
  font-family:              $font-family-h2;
  font-weight:              $font-weight-h2;
  font-size:                $font-size-h2;
  line-height:              $line-height-h2;
  text-transform:           $text-transform-h2;
  color:                    $color-h2;
  margin-top:               $margin-top-h2;
  margin-bottom:            $margin-bottom-h2;
}

//=============== SETTING FOR H3

$font-family-h3: inherit;
$font-weight-h3: 300;
$font-size-h3: 30px;
$line-height-h3: 1.1;
$text-transform-h3: none;
$color-h3: #487EAC;
$padd-top-h3: 20px;
$padd-bottom-h3: 10px;

h3 {
  font-family:              $font-family-h3;
  font-weight:              $font-weight-h3;
  font-size:                $font-size-h3;
  line-height:              $line-height-h3;
  text-transform:           $text-transform-h3;
  color:                    $color-h3;
  padding-top:              $padd-top-h3;
  padding-bottom:            $padd-bottom-h3;
}

//=============== SETTING FOR P

$font-family-p: inherit;
$font-weight-p: 400;
$font-size-p: 16px;
$line-height-p: 1.4;
$text-transform-p: none;
$color-p: #2E4453;
$margin-top-p: 0;
$margin-bottom-p: 10px;

p {
  font-family:              $font-family-p;
  font-weight:              $font-weight-p;
  font-size:                $font-size-p;
  line-height:              $line-height-p;
  text-transform:           $text-transform-p;
  color:                    $color-p;
  margin-top:               $margin-top-p;
  margin-bottom:            $margin-bottom-p;
}

//=============== SETTING FOR A

$font-family-a: inherit;
$font-weight-a: 500;
$font-size-a: 14px;
$text-decoration-a: none;
$color-a: #2E4453;

a, a:hover, a:active {
  font-family:              $font-family-a;
  font-weight:              $font-weight-a;
  font-size:                $font-size-a;
  text-decoration:          $text-decoration-a;
  color:                    $color-a;
}

//@media (min-width: 1199px) {
//  .white-bg{
//    background: url("../../img/white-bg_1.png");
//    z-index: 3;
//    margin-top: 60px;
//  }
//}

