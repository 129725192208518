.header{
  width: 100%;
  padding: 35px 0;
  position: fixed;
  z-index: 9;
  -webkit-transition: padding .3s;
  transition: padding .3s;

  .header-wrap{
    .nav {
      max-width: 520px;
      width: 100%;
      margin-left: 40px;
      @media (max-width: 1199px) {
        max-width: 470px;
      }
      a {
        height: 30px;
        display: block;
        &:after {
          content: '';
          display: block;
          width: 33px;
          height: 5px;
          opacity: 0;
          background: #1DD492;
          margin: 3px auto;
        }
        &:hover {
          &:after {
            opacity: 1!important;
          }
        }
      }
      .active {
        span:after {
          content: '';
          display: block;
          width: 33px;
          height: 5px;
          background: #1DD492;
          margin: 3px auto;
        }
      }
      li.active{
        a:hover{
          &:after{
            opacity: 0!important;
          }
        }
      }
    }
  }
  @media (max-width: 1199px) {
    background: linear-gradient(90deg, #E9EFF3 34%, #f5f9fc 10%);
  }
  @media (max-width: 991px) {
    background: linear-gradient(79.52deg, #F1F5F8 11.63%, #F5F9FC 100%);
  }
}

.header-logo-mob {
  display: none;
}
@media (max-width: 767px) {
  .header-logo-mob {
    display: block;
    background: linear-gradient(79.52deg, #F1F5F8 11.63%, #F5F9FC 100%);
    padding-top: 35px;
    .logo {
      margin-top: 25px;
    }
    .dropdown {
      margin-left: 0px;
      .dropdown-content {
        margin-left: -76px;
      }
      .arrow_up {
        margin-left: 65px;
      }
    }
  }
  .header {
    display: none;
  }
}


.stick {
  background: #fff!important;
  box-shadow: 0px 2px 10px rgba(75, 111, 137, 0.16) !important;
  padding: 20px 0!important;
}

//-----background------
.bg{
  background: url("../../img/bg_2.png") no-repeat;
  background-size: cover!important;
  position: absolute;
  max-width: 1236px;
  width: 100%;
  height: 955px;
  top: 0;
  .d9 {
    width: 100%;
    height: 0;
    position: absolute;
    z-index: 1;
    bottom: 0;
    border-bottom: 325px solid #fff;
    border-right: 1245px solid transparent;
  }
  @media (max-width: 1199px) {
    display: none;
  }
}

.dddd {
  position: relative;
  max-width: 1920px;
  margin: auto;
}

/* lang switch */
.dropbtn {
  cursor: pointer;
  height: 20px;
  width: 20px;
}

.dropdown {
  position: relative;
  display: inline-block;
  margin-left: 25px;
  z-index: 999;
}

.dropdown-content {
  a{
    margin: 8px 0px;
  }
  position: absolute;
  visibility: hidden;
  transition: 0.1s 0.1s;
  width: 170px;
  background-color: #c6cbd0;
  border-radius: 2px;
  padding: 4px 10px 4px 11px;
  margin-top: 10px;
  margin-left: -105px;
  font-size: 13px;
}
.dropdown-content a {
  color: black;
  padding: 2px 0px 2px 35px;
  text-decoration: none;
  display: block;
}

.dropdown:hover .dropdown-content {
  visibility: visible;
  transition-delay: 0.2s;
}

.arrow_up{
  width: 0;
  height: 0;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #c6cbd0;
  border-left: 10px solid transparent;
  position: absolute;
  margin-top: -10px;
  margin-left: 94px;
}

.dropdown .ru{
  background: url("../../img/country-flags.png") no-repeat 0 -14px;
}
.dropdown .vn{
  background: url("../../img/country-flags.png") no-repeat 0 -131px;
}
.dropdown .en{
  background: url("../../img/country-flags.png") no-repeat 0 -61px;
}
.dropdown .lao{
  background: url("../../img/country-flags.png") no-repeat 0 -178px;
}
.dropdown .th{
  background: url("../../img/country-flags.png") no-repeat 0 -39px;
}
.dropdown .id{
  background: url("../../img/country-flags.png") no-repeat 0 -155px;
}
.dropdown .my{
  background: url("../../img/country-flags.png") no-repeat 0 -201px;
}
.dropdown .cn{
  background: url("../../img/country-flags.png") no-repeat 0 -246px;
}
.dropdown .es{
  background: url("../../img/country-flags.png") no-repeat 0 -109px;
}
.dropdown .ph{
  background: url("../../img/country-flags.png") no-repeat 0 -269px;
}

.link-active{
  text-decoration: underline #47b6de;
}
