//<a href="#" class="main-btn">Получить бонус $50</a>

.main-btn {
  display: table;
  border-radius: 100px;
  border: none;
  background: #1CD688;
  box-shadow: 0px 2px 4px rgba(28, 214, 136, 0.2);
  padding: 14px 30px;
  font-size: 16px!important;
  color: white !important;
  font-weight: bold!important;
  line-height: 1.846;
  cursor: pointer;
  &:hover{
    color: #fff;
    box-shadow: none;
  }
}