.footer-wrap {
  .footer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    color: #2E4453;
    font-size: 13px;
    @media (max-width: 992px) {
      flex-direction: column-reverse;
      align-items: center;
      .left-text, .right-text {
        text-align: center;
      }
    }
    .right-text {
      padding-bottom: 30px;
      img {
        padding-top: 5px;
      }
      @media (min-width: 992px) {
        text-align: right;
      }
    }
  }
}

