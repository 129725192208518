.screen-2-wrap {
  position: relative;
  z-index: 2;
  //padding-top: 9px;
  .screen-2 {
    //&:before {
    //  display: block;
    //  content: "";
    //  height: 70px;
    //  margin: -70px 0 0;
    //  @media (max-width: 991px) {
    //    background: #fff;
    //  }
    //}
    @media (max-width: 991px) {
      //padding-top: 20px;
      background: linear-gradient(0.48deg, #F3F5F7 0.11%, rgba(200, 215, 225, 0.14) 99.86%);
    }
  }
  h1 {
    max-width: 700px;
    line-height: 1.2;
    margin-bottom: 40px;
    @media (max-width: 360px) {
      margin-bottom: 0px;
    }
  }
  @media (max-width: 540px) {
    h3{
      font-size: 24px;
      padding-top: 30px;
    }
  }
  @media (max-width: 420px) {
    h3{
      font-size: 20px;
    }
  }
  .item-list {
    padding: 60px 0 100px;
    justify-content: space-between;
    @media (max-width: 1199px) {
      padding: 50px 0 50px;
    }
    @media (max-width: 991px) {
      justify-content: space-around;
    }
    @media (max-width: 540px) {
      padding: 20px 0 20px;
    }
    .item-wrap {
      margin: 15px 0;
      min-width: 182px;
    }
    .img-wrap {
      height: 86px;
    }
    .d1 {
      width: 100px;
      height: 100px;
      background: #DEFFF3;
      border-radius: 50%;
    }
    .item-text {
      max-width: 175px;
      font-size: 15px;
      text-align: center;
      padding-top: 10px;
      .support {
        font-weight: 700;
        background-color: #487eac;
        -webkit-border-radius: 2px;
        border-radius: 2px;
        padding: 0 5px;
        color: #fff;
      }
    }
  }
  @media (min-width: 1199px) {
    .white-bg{
      background: url("../../img/white-bg_1.png");
      background-size: auto;
      z-index: 3;
      margin-top: 47px;
    }
  }
}