.screen-3-wrap{
  &:before {
    display: block;
    content: "";
    height: 70px;
    margin: -70px 0 0;
  }
  .screen-3 {
    z-index: 2;
    position: relative;
    background: linear-gradient(0.18deg, #F3F5F7 0.11%, rgba(255, 255, 255, 0) 51.91%);
    padding-bottom: 80px;
    @media (max-width: 540px) {
      padding-bottom: 30px;
      h3{
        font-size: 24px;
      }
    }
    @media (max-width: 420px) {
      h3{
        font-size: 20px;
        padding-top: 30px;
      }
    }
    .swiper-container {
      width: 100%;
      height: 100%;
      margin-left: auto;
      margin-right: auto;
    }
    .swiper-slide {
      background-size: cover;
      background-position: center;
    }
    .gallery-top {
      height: 100%;
      width: 100%;
    }
    .gallery-top .swiper-wrapper .swiper-slide{
      img {
        //height: 570px;
        width: 100%;
      }

      //background: url("../../img/Screenshot_1.png") no-repeat;
      //background-size: contain;
      //@media (max-width: 1199px) {
      //  height: 474px;
      //}
      //@media (max-width: 991px) {
      //  height: 351px;
      //}
      //@media (max-width: 767px) {
      //  height: 261px;
      //}
      //@media (max-width: 575px) {
      //  height: 278px;
      //}
      //@media (max-width: 480px) {
      //  height: 225px;
      //}
      //@media (max-width: 420px) {
      //  height: 200px;
      //}
      //@media (max-width: 360px) {
      //  height: 175px;
      //}
      //@media (max-width: 340px) {
      //  height: 158px;
      //}
    }
    .gallery-thumbs {
      box-sizing: border-box;
      padding: 10px 0;
      overflow: hidden;
    }
    .gallery-thumbs .swiper-slide {
      color: #a8bece;
      text-align: center;
      cursor: pointer;
    }
    .gallery-thumbs .swiper-slide-thumb-active {
      color: #2e4453;
      span:after {
        content: '';
        display: block;
        width: 33px;
        height: 5px;
        background: #1DD492;
        margin: 3px auto;
      }
    }
    @media (min-width: 1199px) {
      .white-bg{
        background: url("../../img/white-bg_1.png");
      }
    }
  }
}
