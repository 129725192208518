//=========================//
//
//        IMPORT
//
//=========================//

@import 'base/fonts';
@import 'base/buttons';

// MODULES IMPORT
//==========================
@import 'modules/header';
@import 'modules/screen-1';
@import 'modules/screen-2';
@import 'modules/screen-3';
@import 'modules/form';
@import 'modules/footer';

body{
  word-wrap: break-word;
}